<template>
    <div class="wrap-offexchange">
        <div class="wrap-content">
            <!-- <div class="title-page">
                <div>
                    <small>{{ $route.matched[1].name }}</small>
                </div>
                {{ $route.name }}
            </div> -->
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
